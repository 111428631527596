import React from "react";
import {Link} from "gatsby"
import Layout from "../../../components/layout"
import Map from "../../../components/map";
import HeaderEN from "../../../components/HeaderEN";
import FooterEN from "../../../components/FooterEN";

const EnglishPage = () => (
    <Layout>
        <HeaderEN/>
        <div className={"container-xxl"}>
            <div className={"c ac jc"}>
                <h2 className={"tac fw-bold c-green"}>Choose Your Path to Employment</h2>
                <Link className={"mt5"} to="/en/health/positions/">
                    <button>Browse all Provincial Positions</button>
                </Link>
                <p className={"fw-bold fs-large my4"}>OR</p>
                <h3 className={"tac"}>Select your preferred region on the map.</h3>
                <Map/>
            </div>
        </div>
        <FooterEN/>
    </Layout>
);

export default EnglishPage; 